import './Headline.scss';

enum HeadlineType {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
}

enum HeadlineWrapper {
  REGULAR, // div
  PARAGRAPH, // p
  SPAN, // span
  HEADER, // h1
}

enum HeadlineSize {
  REGULAR = '', // title
  BIG = 'xl', // title xl
  GRAND = 'xxl', // title xxl
  HUGE = 'xxxl', // title xxxl
}

enum HeadlineWeight {
  REGULAR = 'regular',
  MEDIUM = 'medium',
  THIN = 'thin',
}

interface HeadlineProps {
  id?: string;
  type: HeadlineType;
  wrapper: HeadlineWrapper;
  size?: HeadlineSize;
  weight?: HeadlineWeight;
  overwrittenFont?: string;
  overwrittenColor?: string;
  fade?: string;
  children: any;
}

const Headline = (props: HeadlineProps) => {
  const setFontOverrideIfApplies = (font?: string): string | null => {
    return !!font ? `font-override ${font}` : null;
  };

  const titleClassName = `${props.type} ${props.size || HeadlineSize.REGULAR} ${
    props.weight
  } ${setFontOverrideIfApplies(props.overwrittenFont)} ${
    props.overwrittenColor
  } ${props.fade}
  `;

  switch (props.wrapper) {
    case HeadlineWrapper.REGULAR:
      return (
        <div id={props.id} className={titleClassName}>
          {props.children}
        </div>
      );
    case HeadlineWrapper.PARAGRAPH:
      return (
        <p id={props.id} className={titleClassName}>
          {props.children}
        </p>
      );
    case HeadlineWrapper.SPAN:
      return (
        <span id={props.id} className={titleClassName}>
          {props.children}
        </span>
      );
    case HeadlineWrapper.HEADER:
      return (
        <h1 id={props.id} className={titleClassName}>
          {props.children}
        </h1>
      );
  }
};

export {
  Headline,
  HeadlineProps,
  HeadlineType,
  HeadlineWrapper,
  HeadlineSize,
  HeadlineWeight,
};
