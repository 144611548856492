import { LocalImage } from '../util/localImage';

const backgroundImages: LocalImage[] = [
  {
    identifier: 'carousel_item_1',
    url: './assets/img/landing_carousel/1.jpg',
    bgPosition: 'top',
  },
  {
    identifier: 'carousel_item_2',
    url: './assets/img/landing_carousel/2.jpg',
    bgPosition: 'bottom',
  },
  {
    identifier: 'carousel_item_3',
    url: './assets/img/landing_carousel/3.jpg',
    bgPosition: 'top',
  },
];

export { backgroundImages };
