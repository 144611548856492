import { backgroundImages } from '../../model/countdown';
import Countdown from 'react-countdown';
import './Countdown.scss';
import {
  Headline,
  HeadlineSize,
  HeadlineType,
  HeadlineWrapper,
  HeadlineWeight,
} from '../../components/Headline/Headline';

const LocalCountdown = () => {
  const contdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }): JSX.Element => {
    return (
      <>
        <div id="countdown-renderer" className="row jus-center ali-center">
          <div className="row-item">
            <span className="number">{days}</span>
            <span className="attribute">días</span>
          </div>
          <div className="row-item">
            <span className="number">{hours}</span>
            <span className="attribute">horas</span>
          </div>
          <div className="row-item">
            <span className="number">{minutes}</span>
            <span className="attribute">minutos</span>
          </div>
          <div className="row-item">
            <span className="number">{seconds}</span>{' '}
            <span className="attribute">segundos</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <section id="countdown-section">
      <div id="grid">
        {backgroundImages.map(({ identifier, url, bgPosition }) => {
          return (
            <div
              className="obscured-img"
              key={identifier}
              style={{
                backgroundImage: `url(${url})`,
                backgroundPosition: bgPosition,
              }}
            ></div>
          );
        })}
      </div>

      <div className="container fade-right" id="timer">
        <div className="wrapper">
          <Headline
            type={HeadlineType.TITLE}
            wrapper={HeadlineWrapper.HEADER}
            size={HeadlineSize.BIG}
            weight={HeadlineWeight.REGULAR}
          >
            Nuestra Boda
          </Headline>
          <Countdown
            date={new Date('06/22/2024 17:00')}
            renderer={contdownRenderer}
          />
        </div>
      </div>
    </section>
  );
};

export default LocalCountdown;
