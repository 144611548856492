import './ImageSection.scss';

const ImageSection = ({ backgroundImage }: { backgroundImage: string }) => {
  const constructedImageUrl = (): string => {
    return 'url(' + backgroundImage + ')';
  };

  return (
    <section
      className="image-section"
      style={{ backgroundImage: constructedImageUrl() }}
    ></section>
  );
};

export default ImageSection;
