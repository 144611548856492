import { Fade } from 'react-slideshow-image';
import { backgroundImages } from '../../model/landing';
import 'react-slideshow-image/dist/styles.css';
import './Landing.scss';
import {
  Headline,
  HeadlineType,
  HeadlineWrapper,
  HeadlineWeight,
} from '../../components/Headline/Headline';

const Landing = () => {
  return (
    <section id="landing-section">
      <div id="slider">
        <Fade arrows={false} pauseOnHover={false} autoplay infinite>
          {backgroundImages.map(({ identifier, url, bgPosition }) => {
            return (
              <div
                className="obscured-img"
                key={identifier}
                style={{
                  backgroundImage: `url(${url})`,
                  backgroundPosition: bgPosition,
                }}
              ></div>
            );
          })}
        </Fade>
      </div>

      <div className="container" id="quote">
        <Headline
          type={HeadlineType.TITLE}
          wrapper={HeadlineWrapper.HEADER}
          weight={HeadlineWeight.REGULAR}
        >
          Que nuestro amor perdure
        </Headline>
        <hr className="h-divider" />
      </div>
    </section>
  );
};

export default Landing;
