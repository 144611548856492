import { LocalImage } from '../util/localImage';

const backgroundImages: LocalImage[] = [
  {
    identifier: 'countdown_item_1',
    url: './assets/img/countdown/1.jpg',
    bgPosition: 'center',
  },
  {
    identifier: 'countdown_item_2',
    url: './assets/img/countdown/2.jpg',
    bgPosition: 'center',
  },
  {
    identifier: 'countdown_item_3',
    url: './assets/img/countdown/3.jpg',
    bgPosition: 'center',
  },
  {
    identifier: 'countdown_item_4',
    url: './assets/img/countdown/4.jpg',
    bgPosition: 'center',
  },
];

export { backgroundImages };
