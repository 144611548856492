enum PlaceType {
  CEREMONIA = 'Ceremonia',
  SALON = 'Salón',
}

interface Place {
  type: PlaceType;
  name: string;
  time: string;
  img: string;
  imgAlt: string;
  mapImg: string;
  mapUrl: string;
  mapBgPosition: string;
}

const placeItems: Place[] = [
  {
    type: PlaceType.CEREMONIA,
    name: 'Parroquia del Sagrado Corazón de Jesús',
    time: '17:00 p.m.',
    img: './assets/img/addresses/iglesia.webp',
    imgAlt: 'Parroquia del Sagrado Corazón de Jesús',
    mapImg: './assets/img/addresses/ceremonia_location.webp',
    mapUrl: 'https://maps.app.goo.gl/UxcCKHSppPjr4dKy7',
    mapBgPosition: '50% 35%',
  },
  {
    type: PlaceType.SALON,
    name: 'Las Almerías',
    time: '20:30 p.m.',
    img: './assets/img/addresses/salon.webp',
    imgAlt: 'Las Almerías',
    mapImg: './assets/img/addresses/salon_location.webp',
    mapUrl: 'https://maps.app.goo.gl/BVxsr7fPV5zEevwX6',
    mapBgPosition: '50% 65%',
  },
];

export { Place, placeItems };
