import {
  Headline,
  HeadlineSize,
  HeadlineType,
  HeadlineWrapper,
  HeadlineWeight,
} from '../../components/Headline/Headline';
import './Dresscode.scss';

const Dresscode = () => {
  return (
    <section id="dress-code-section">
      <div className="container wrapper col s-gap jus-center ali-center fade-left">
        <Headline
          type={HeadlineType.TITLE}
          wrapper={HeadlineWrapper.REGULAR}
          size={HeadlineSize.BIG}
          weight={HeadlineWeight.REGULAR}
        >
          Código de Vestimenta
        </Headline>
        <Headline
          type={HeadlineType.SUBTITLE}
          wrapper={HeadlineWrapper.REGULAR}
          overwrittenFont="quincy-thin"
        >
          Formal
        </Headline>
      </div>
    </section>
  );
};

export default Dresscode;
