import { ColumnImages } from '../util/columnImages';

const backgroundImages: ColumnImages[] = [
  {
    identifier: 'confirm_list_col_1',
    items: [
      {
        identifier: 'major-col-item',
        url: './assets/img/confirm/IMG_6794.jpg',
        bgPosition: 'center',
      },
      {
        identifier: 'minor-col-item',
        url: './assets/img/confirm/IMG_6753.jpg',
        bgPosition: 'center',
      },
    ],
  },
  {
    identifier: 'confirm_list_col_2',
    items: [
      {
        identifier: 'major-col-item',
        url: './assets/img/confirm/IMG_6777.jpg',
        bgPosition: 'center',
      },
    ],
  },
  {
    identifier: 'confirm_list_col_3',
    items: [
      {
        identifier: 'major-col-item',
        url: './assets/img/confirm/IMG_6795.jpg',
        bgPosition: 'center',
      },
    ],
  },
  {
    identifier: 'confirm_list_col_4',
    items: [
      {
        identifier: 'major-col-item',
        url: './assets/img/confirm/IMG_6738.jpg',
        bgPosition: 'center',
      },
      {
        identifier: 'minor-col-item',
        url: './assets/img/confirm/IMG_6749.jpg',
        bgPosition: 'center',
      },
    ],
  },
];

export { backgroundImages };
