import { isMobile } from 'react-device-detect';
import { Place, placeItems } from '../../model/places';
import './Places.scss';
import {
  Headline,
  HeadlineType,
  HeadlineWrapper,
} from '../../components/Headline/Headline';
import { Label, LabelWrapper } from '../../components/Label/Label';

const Places = () => {
  return (
    <section id="addresses-section">
      <div
        className="container set-v-margin col s-gap"
        id="addresses-container"
      >
        {placeItems.map((item: Place) => {
          return (
            <div key={item.type} id="address-content" className="col fade-left">
              <Headline
                type={HeadlineType.TITLE}
                wrapper={HeadlineWrapper.HEADER}
                overwrittenColor="goldish-font-color"
              >
                {item.type}
              </Headline>
              <div id="address-content-details" className="row l-gap">
                <img
                  id="address-img"
                  className="obscured-img"
                  src={item.img}
                  alt={item.imgAlt}
                />
                <div className="col s-gap full-flex">
                  <Headline
                    type={HeadlineType.SUBTITLE}
                    wrapper={HeadlineWrapper.REGULAR}
                    overwrittenFont="quincy-thin"
                  >
                    {item.name}
                  </Headline>
                  <Label
                    wrapper={LabelWrapper.REGULAR}
                    overwrittenFont="quincy-thin"
                  >
                    {item.time}
                  </Label>
                  {isMobile ? (
                    <a
                      id="address-link"
                      href={item.mapUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Label
                        id="address-link"
                        wrapper={LabelWrapper.REGULAR}
                        overwrittenColor="goldish-font-color"
                        overwrittenFont="quincy-regular"
                      >
                        Haz clic aquí para ver ubicación
                      </Label>
                    </a>
                  ) : (
                    <div
                      id="address-map"
                      style={{
                        backgroundPosition: item.mapBgPosition,
                        backgroundImage: 'url(' + item.mapImg + ')',
                      }}
                      onClick={() => window.open(item.mapUrl, '_blank')}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Places;
