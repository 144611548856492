// React
import { useEffect, useState } from 'react';
// Interfaces
import { Guest } from './model/util/guest';
// Sections imports
import Confirmation from './sections/Confirmation/Confirmation';
import LocalCountdown from './sections/Countdown/Countdown';
import Dresscode from './sections/Dresscode/Dresscode';
import Gifts from './sections/Gifts/Gifts';
import Invitation from './sections/Invitation/Invitation';
import Landing from './sections/Landing/Landing';
import Places from './sections/Places/Places';
// Components
import ImageSection from './components/ImageSection/ImageSection';

const WeddingInvite = () => {
  // MARK: - useState Variables
  const [guest, setGuest] = useState<Guest | undefined>(undefined);

  // MARK: - Methods
  const processParams = (): void => {
    const queryParams = new URLSearchParams(window.location.search);

    const nameParams = queryParams.get('name') ?? undefined;
    const invitedParams = queryParams.get('invited') ?? undefined;

    if (
      typeof nameParams === 'undefined' ||
      typeof invitedParams === 'undefined'
    ) {
      return;
    }

    const name = nameParams.split(/(?=[A-Z])/).join(' ');
    const invited = parseInt(invitedParams);

    const guest: Guest = { name, invited };
    setGuest(guest);
  };

  const fadeListeners = (): void => {
    let fadeViews = [
      document.querySelectorAll('.fade-right'),
      document.querySelectorAll('.fade-left'),
    ];

    fadeViews.forEach((view) => {
      view.forEach((innerView) => {
        const windowHeight = window.innerHeight;
        const elementTop = innerView.getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          innerView.classList.add('active');
        } else {
          innerView.classList.remove('active');
        }
      });
    });
  };

  // MARK: - useEffect
  useEffect(() => {
    processParams();
    window.addEventListener('scroll', () => fadeListeners());
  }, []);

  return (
    <div>
      <Landing />
      {/*  */}
      {/*  */}
      {/*  */}
      <LocalCountdown />
      {/*  */}
      {/*  */}
      {/*  */}
      <Invitation guest={guest} />
      {/*  */}
      {/*  */}
      <Gifts />
      {/*  */}
      {/*  */}
      {/*  */}
      <ImageSection backgroundImage="assets/img/confirm/IMG_6738.jpg" />
      {/*  */}
      {/*  */}
      {/*  */}
      <Places />
      {/*  */}
      {/*  */}
      {/*  */}
      <Dresscode />

      <Confirmation guest={guest} />
    </div>
  );
};

export default WeddingInvite;
