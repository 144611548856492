import { isMobile } from 'react-device-detect';
import { RxEnvelopeClosed } from 'react-icons/rx';
import { GoGift } from 'react-icons/go';
import { FaAmazon } from 'react-icons/fa';
import './Gifts.scss';
import {
  Headline,
  HeadlineSize,
  HeadlineType,
  HeadlineWrapper,
} from '../../components/Headline/Headline';
import { Label, LabelWrapper } from '../../components/Label/Label';

const Gifts = () => {
  return (
    <section id="gifts-section">
      <div id="gifts-container" className="container set-v-padding">
        <div id="gifts-titles" className="fade-left">
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            overwrittenFont="quincy-thin"
          >
            No niños
          </Headline>
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            overwrittenFont="quincy-thin"
          >
            Opciones de regalo
          </Headline>
        </div>

        <div
          id="gifts-list"
          className="row jus-center ali-start text-center fade-left"
        >
          <div className="row-item col s-gap jus-center ali-center">
            <RxEnvelopeClosed className="gift-icon" />
            <Label
              wrapper={LabelWrapper.REGULAR}
              overwrittenFont="dmsans-medium"
            >
              Sobre
            </Label>
          </div>
          {!isMobile && <hr className="v-divider grayish" />}

          <div className="row-item">
            <a
              className="no-underline col s-gap jus-center ali-center"
              href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51289104"
              target="_blank"
              rel="noreferrer"
            >
              <GoGift className="gift-icon" />
              <Label
                wrapper={LabelWrapper.REGULAR}
                overwrittenFont="dmsans-medium"
              >
                Liverpool #51289104
              </Label>
            </a>
          </div>
          {!isMobile && <hr className="v-divider grayish" />}

          <div className="row-item">
            <a
              className="no-underline col s-gap jus-center ali-center"
              href="https://www.amazon.com.mx/wedding/registry/12CIERX2Y3MWZ"
              target="_blank"
              rel="noreferrer"
            >
              <FaAmazon className="gift-icon clickable" />
              <Label
                wrapper={LabelWrapper.REGULAR}
                overwrittenFont="dmsans-medium"
              >
                Registro Amazon
              </Label>
            </a>
          </div>
        </div>

        <div
          id="bank-info"
          className="col s-gap jus-center ali-center text-center"
        >
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            size={HeadlineSize.BIG}
            overwrittenFont="quincy-thin"
          >
            Si es de tu preferencia hacer una transferencia electrónica
          </Headline>
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            size={HeadlineSize.BIG}
            overwrittenFont="quincy-thin"
          >
            Te compartimos los datos
          </Headline>
          <Label
            wrapper={LabelWrapper.REGULAR}
            overwrittenFont="dmsans-regular"
          >
            <b>Banco:</b> BBVA
          </Label>
          <Label
            wrapper={LabelWrapper.REGULAR}
            overwrittenFont="dmsans-regular"
          >
            <b>Cuenta:</b> 1551454946
          </Label>
          <Label
            wrapper={LabelWrapper.REGULAR}
            overwrittenFont="dmsans-regular"
          >
            <b>CLABE:</b> 012580015514549466
          </Label>
          <Label
            wrapper={LabelWrapper.REGULAR}
            overwrittenFont="dmsans-regular"
          >
            <b>Nombre:</b> Barbara Isabel Martínez Flores
          </Label>
        </div>
      </div>
    </section>
  );
};

export default Gifts;
