import {
  Headline,
  HeadlineSize,
  HeadlineType,
  HeadlineWrapper,
  HeadlineWeight,
} from '../../components/Headline/Headline';
import { backgroundImages } from '../../model/confirmation';
import { Guest } from '../../model/util/guest';
import './Confirmation.scss';

const Confirmation = ({ guest }: { guest: Guest | undefined }) => {
  const whatsappTest = false;

  const sendMessage = (): void => {
    if (!guest) {
      return;
    }

    const personaText = guest.invited > 1 ? 'personas' : 'persona';
    const cellphone = whatsappTest
      ? process.env.REACT_APP_BARBIE_BRYANT_TEST_PHONE_NUMBER
      : process.env.REACT_APP_BARBIE_BRYANT_PHONE_NUMBER;
    const msg = `¡Hola! ${guest.name} confirma la asistencia de ${
      guest!.invited
    } ${personaText} a la boda de Barbie y Bryant el 22 de Junio del 2024`;
    const url = `https://wa.me/${cellphone}?text=${msg}`;

    window.open(url, '_blank');
  };

  return (
    <section id="confirm-section">
      <div id="confirm-grid" className="row">
        {backgroundImages.map(({ identifier, items }) => {
          return (
            <div key={identifier} className="confirm-column row-item col">
              {items.map(({ identifier, url, bgPosition }) => {
                return (
                  <div
                    key={url}
                    id={identifier}
                    className="obscured-img xtra-dark"
                    style={{
                      backgroundImage: `url(${url})`,
                      backgroundPosition: bgPosition,
                    }}
                  ></div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div id="confirm-container" className="container fade-right">
        <div id="confirm-column" className="wrapper col jus-center ali-center">
          <Headline
            type={HeadlineType.TITLE}
            wrapper={HeadlineWrapper.REGULAR}
            size={HeadlineSize.BIG}
            weight={HeadlineWeight.REGULAR}
          >
            Confirmación
          </Headline>
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            size={HeadlineSize.REGULAR}
            overwrittenFont="quincy-thin"
          >
            Nos hace mucha ilusión la posibilidad de poder compartir este día
            tan especial con ustedes.
          </Headline>
          <button id="btn" disabled={!guest} onClick={() => sendMessage()}>
            Confirmar asistencia
          </button>
        </div>
      </div>
    </section>
  );
};

export default Confirmation;
