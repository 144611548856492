import './Label.scss';

enum LabelWrapper {
  REGULAR,
  PARAGRAPH,
  SPAN,
}

enum LabelJustified {
  START = 'jus-start',
  CENTER = 'jus-center',
  END = 'jus-end',
}

enum LabelAlignemnt {
  START = 'ali-start',
  CENTER = 'ali-center',
  END = 'ali-end',
}

interface LabelPosition {
  justified: LabelJustified;
  alignment: LabelAlignemnt;
}

interface LabelProps {
  id?: string;
  wrapper: LabelWrapper;
  position?: LabelPosition;
  overwrittenFont?: string;
  overwrittenColor?: string;
  fade?: string;
  children: any;
}

const Label = (props: LabelProps) => {
  const setFontOverrideIfApplies = (font?: string): string | null => {
    return !!font ? `font-override ${font}` : null;
  };

  const setPositionIfApplies = (position?: LabelPosition): string | null => {
    return !!position
      ? `row xs-gap ${position.justified} ${position.alignment}`
      : null;
  };

  const titleClassName = `
    label ${setPositionIfApplies(props.position)} ${setFontOverrideIfApplies(
    props.overwrittenFont
  )} ${props.overwrittenColor} ${props.fade}
  `;
  switch (props.wrapper) {
    case LabelWrapper.REGULAR:
      return (
        <div id={props.id} className={titleClassName}>
          {props.children}
        </div>
      );
    case LabelWrapper.PARAGRAPH:
      return (
        <p id={props.id} className={titleClassName}>
          {props.children}
        </p>
      );
    case LabelWrapper.SPAN:
      return (
        <span id={props.id} className={titleClassName}>
          {props.children}
        </span>
      );
  }
};

export { Label, LabelWrapper, LabelPosition, LabelJustified, LabelAlignemnt };
