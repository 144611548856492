import { isMobileOnly } from 'react-device-detect';
import { Guest } from '../../model/util/guest';
import './Invitation.scss';
import {
  Headline,
  HeadlineSize,
  HeadlineType,
  HeadlineWrapper,
  HeadlineWeight,
} from '../../components/Headline/Headline';
import {
  Label,
  LabelWrapper,
  LabelJustified,
  LabelAlignemnt,
} from '../../components/Label/Label';
import { PiCrossLight } from 'react-icons/pi';

const Invitation = ({ guest }: { guest: Guest | undefined }) => {
  const guestInfoRenderer = (): JSX.Element => {
    if (!!guest) {
      return (
        <>
          <Headline
            type={HeadlineType.TITLE}
            wrapper={HeadlineWrapper.REGULAR}
            size={HeadlineSize.REGULAR}
            weight={HeadlineWeight.THIN}
            overwrittenFont="quincy-thin"
          >
            {guest.name}
          </Headline>
          <Headline
            type={HeadlineType.SUBTITLE}
            wrapper={HeadlineWrapper.REGULAR}
            overwrittenFont="quincy-thin"
          >
            {guest.invited} {guest.invited > 1 ? 'invitados' : 'invitado'}
          </Headline>
        </>
      );
    }

    return (
      <div>
        <Headline
          type={HeadlineType.TITLE}
          wrapper={HeadlineWrapper.REGULAR}
          size={HeadlineSize.REGULAR}
          weight={HeadlineWeight.THIN}
          overwrittenFont="quincy-thin"
        >
          ¿No aparece tu nombre?
        </Headline>
        <Label wrapper={LabelWrapper.PARAGRAPH}>
          Contacta a Barbie o Bryant para solucionar el problema
        </Label>
      </div>
    );
  };

  return (
    <section id="invitation-section">
      <div id="signature-container" className="container fade-left">
        <Headline
          id="signature"
          type={HeadlineType.TITLE}
          wrapper={HeadlineWrapper.HEADER}
          size={HeadlineSize.HUGE}
          weight={HeadlineWeight.THIN}
          overwrittenFont="quincy-thin"
        >
          B
        </Headline>
        <Headline
          id="signature"
          type={HeadlineType.TITLE}
          wrapper={HeadlineWrapper.HEADER}
          size={HeadlineSize.HUGE}
          weight={HeadlineWeight.THIN}
          overwrittenFont="quincy-thin"
        >
          B
        </Headline>
      </div>
      <div className="container" id="data-container">
        <Label id="message" wrapper={LabelWrapper.PARAGRAPH} fade="fade-left">
          Con la bendición de Dios y nuestros padres tenemos el honor de
          invitarte a la celebración de nuestro matrimonio
        </Label>
        <div id="parents" className="row jus-center ali-center fade-left">
          <div className="row-item col">
            <Label wrapper={LabelWrapper.REGULAR}>
              Pablo Martinez de la Torre
            </Label>
            <Label wrapper={LabelWrapper.REGULAR}>
              Adriana Isabel Flores Chapa
            </Label>
          </div>
          <hr
            className={
              isMobileOnly
                ? 'h-divider grayish reduced-opacity quarter'
                : 'v-divider grayish'
            }
          />
          <div className="row-item col">
            <Label
              wrapper={LabelWrapper.REGULAR}
              position={{
                justified: LabelJustified.CENTER,
                alignment: LabelAlignemnt.CENTER,
              }}
            >
              Juan Carlos Muñoz Vargas <PiCrossLight />
            </Label>
            <Label wrapper={LabelWrapper.REGULAR}>Esmeralda Cano Aguilar</Label>
          </div>
        </div>
        <div id="invite">
          <Label wrapper={LabelWrapper.REGULAR} fade="fade-left">
            Nosostros
          </Label>
          <Headline
            type={HeadlineType.TITLE}
            wrapper={HeadlineWrapper.PARAGRAPH}
            size={HeadlineSize.HUGE}
            overwrittenColor="goldish-font-color"
            fade="fade-left"
          >
            Barbara y Bryant
          </Headline>
          <Label wrapper={LabelWrapper.PARAGRAPH} fade="fade-left">
            Nos unimos en matrimonio y tenemos el honor de invitarte el día
          </Label>
          <div id="date" className="row jus-center ali-center fade-left">
            <div className="row-item">
              <Headline
                type={HeadlineType.TITLE}
                wrapper={HeadlineWrapper.REGULAR}
                overwrittenColor="goldish-font-color"
                overwrittenFont="dmsans-regular"
                fade="fade-left"
              >
                Sábado
              </Headline>
            </div>
            <div className="row-item">
              <Headline
                type={HeadlineType.TITLE}
                wrapper={HeadlineWrapper.REGULAR}
                overwrittenColor="goldish-font-color"
                overwrittenFont="dmsans-regular"
                fade="fade-left"
              >
                22 Junio
              </Headline>
            </div>
            <div className="row-item">
              <Headline
                type={HeadlineType.TITLE}
                wrapper={HeadlineWrapper.REGULAR}
                overwrittenColor="goldish-font-color"
                overwrittenFont="dmsans-regular"
                fade="fade-left"
              >
                2024
              </Headline>
            </div>
          </div>
        </div>
        <div id="guest-data" className="fade-left">
          {guestInfoRenderer()}
        </div>
      </div>
    </section>
  );
};

export default Invitation;
